/*=============== GOOGLE FONTS ===============*/
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;500;600;700;800;900&family=Raleway:wght@700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3.5rem; // 56px

  /*========== Colors ==========*/
  /*Color*/
  --primary-color: #3e6553;
  --first-color: #fffaf0;
  --price-color: #9b1b30;
  --text-color: rgb(0, 0, 0);
  --text-body-color: #565d59;
  --text-color-light: #b2b2b2;
  --primary-color-light: #e6efeb;
  --text-price: #003311;

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  // --body-font: "Patrick Hand";
  --body-font: "Roboto", sans-serif;
  --title-font: "Roboto", sans-serif;

  --big-font-size: 2rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  --img-transition: 0.3s;
  --img-hidden: hidden;
  --img-scale: scale(1.1);

  // Responsive typography
  @media screen and (min-width: 968px) {
    --big-font-size: 3.5rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margenes Bottom ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}
