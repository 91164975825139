.home {
  .header {
    &__container {
      position: relative;
      width: 100%;
      height: 90vh;
      z-index: 1;
      padding: 20px;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      z-index: 0;
    }

    &__content {
      position: relative;
      display: grid;
      align-content: center;
      align-items: center;
      height: 100%;
      max-width: 1400px;
      margin: 0 auto;

      &-title {
        font-size: 5.5rem;
        font-weight: 800;
        // letter-spacing: -2px;
        font-family: var(--title-font);

        p {
          letter-spacing: 0px;
          font-size: var(--h1-font-size);
        }
      }

      &-desc {
        font-size: var(--normal-font-size) !important;
        max-width: 600px;
        margin-bottom: 20px;
        font-weight: 300;
      }

      .btn-primary {
        padding: 0.5rem 1.5rem !important;
      }

      .home__social {
        display: grid;
        row-gap: 4.5rem;
        position: absolute;
        right: 1rem;
        justify-items: center;

        &-follow {
          font-weight: var(--font-medium);
          font-size: var(--normal-font-size);
          color: var(--first-color);
          position: relative;
          transform: rotate(90deg);
          color: #fff;
          z-index: 99;

          &::after {
            content: "";
            position: absolute;
            width: 1rem;
            height: 2px;
            background-color: var(--first-color);
            right: -45%;
            top: 50%;
          }
        }

        &-links {
          display: inline-flex;
          flex-direction: column;
          row-gap: 0.25rem;
        }

        &-link {
          color: #fff;
          transition: 0.3s;
          font-size: var(--h3-font-size);
          &:hover {
            transform: translateX(0.25rem);
          }
        }
      }
    }

    &__content-title,
    &__content {
      color: #fff !important;
    }
  }

  .about {
    &__img {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      justify-content: center;
    }

    &__img-overlay {
      overflow: var(--img-hidden);
      border-radius: 5px;
    }

    img {
      transition: var(--img-transition);

      &:hover {
        transform: var(--img-scale);
      }
    }

    &__img-overlay:nth-child(1) {
      max-width: 250px;
    }

    &__img-overlay:nth-child(2) {
      max-width: 270px;
    }

    &__content {
      display: grid;
      text-align: justify;
      gap: 1rem;

      .title {
        font-size: 2rem;
      }
    }
  }

  .sport-title {
    font-size: 2rem;
  }

  .product {
    &__container {
      padding: 4rem 0 0 0;
    }
  }

  .MuiButton-endIcon {
    transition: 0.3s;
  }

  a:hover {
    .MuiButton-endIcon {
      transform: translateX(0.25rem);
    }
  }

  .advise {
    background-color: var(--primary-color-light);
    padding-bottom: 4rem;
    h1 {
      font-size: var(--h1-font-size);
      color: var(--text-color);
    }

    h1,
    p {
      margin-bottom: 20px;
    }

    img {
      border-radius: 20px;
      height: 300px;
      display: block;
      margin: 0 auto;
    }
  }

  .commit {
    padding-top: 5rem;

    .title {
      font-size: 2rem;
    }

    .container {
      padding-left: 5rem;
      padding-right: 5rem;
    }

    &__content {
      display: grid;
      gap: 1rem;
      text-align: justify;
    }

    &__img {
      width: 400px;
      margin: 0 auto;
      display: block;
    }

    &__details {
      display: grid;
      row-gap: 1rem;
      margin-bottom: var(--mb-2);

      &-description {
        display: inline-flex;
        column-gap: 0.5rem;
        font-size: var(--small-font-size);
      }

      &-icon {
        font-size: 1rem;
        color: var(--primary-color);
        margin-top: 0.15rem;
      }
    }
  }

  .blog {
    &__card {
      box-shadow: none;
      margin: 0 auto;

      &-img {
        border-radius: 20px;
      }

      &-content {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .sale {
    padding-bottom: 0 !important;

    &__title {
      display: flex;
      justify-content: space-between;

      label {
        color: var(--first-color-darkness);
        margin-left: 5px;
      }

      img {
        max-width: 200px;
        padding-left: 10px;
        animation: example 1s infinite ease-in-out;
      }

      .container {
        overflow: var(--img-hidden);
      }
    }

    // img {
    //   transition: var(--img-transition);

    //   &:hover {
    //     transform: scale(1.05);
    //   }
    // }

    @keyframes example {
      0% {
        -webkit-transform: rotate(0) scale(1) skew(1deg);
      }
      10% {
        -webkit-transform: rotate(-5deg) scale(1) skew(1deg);
      }
      20% {
        -webkit-transform: rotate(5deg) scale(1) skew(1deg);
      }
      30% {
        -webkit-transform: rotate(-5deg) scale(1) skew(1deg);
      }
      40% {
        -webkit-transform: rotate(5deg) scale(1) skew(1deg);
      }
      50% {
        -webkit-transform: rotate(0) scale(1) skew(1deg);
      }
      100% {
        -webkit-transform: rotate(0) scale(1) skew(1deg);
      }
    }

    .container:nth-child(2) {
      overflow: var(--img-hidden);
      max-width: 1200px;
      padding: 0 5px;
      border-radius: 10px;
    }
  }
}

.footer {
  padding-bottom: 0 !important;

  // .container {
  //   background-size: 50%;
  //   background-repeat: no-repeat;
  //   background-position: bottom;
  // }

  &__logo {
    width: 200px;
    display: block;
    margin: 20px auto;
  }

  &__register {
    background-color: var(--primary-color);
    padding: 80px 40px;
    border-radius: 10px;
    color: #fff;
    margin-bottom: 40px;
    max-width: 1024px;

    &-desc,
    .title {
      text-align: center;
      font-weight: 600;
      color: #fff;
    }

    &-desc {
      font-weight: 400;
      padding: 0 10rem;
    }

    .title {
      margin-bottom: 10px;
    }

    &-form {
      display: flex;
      justify-content: center;
      gap: 1rem;
      max-width: 500px;
      margin: 30px auto 0 auto;
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;

      button {
        width: 200px;
      }
    }
  }
}
