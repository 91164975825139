.category {
  &__container {
    margin: 30px auto 0 auto;
    // max-width: 1400px;
  }

  &__image {
    border-radius: 5px;
    width: 100%;
  }

  &__filter {
    &-brands {
      max-height: 500px;
      min-height: 400px;
      overflow-y: scroll;
    }
  }

  .sticky {
    position: sticky;
    top: 80px;
    padding-right: 10px;
  }

  &__title {
    font-size: var(--h3-font-size);
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid var(--first-color-darkness);
    color: var(--text-color);
  }

  &__content {
    margin-top: 20px;
  }

  .sale {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: end;

      &-left {
        font-size: var(--h1-font-size);
        font-weight: var(--font-medium);
      }

      &-right > p {
        padding-left: 10px;
      }
    }

    .container {
      padding: 0;
    }
  }
}
