.roulette {
  padding-top: 80px;

  &_time {
    font-size: 17px;
    color: #fff;
    text-align: center;
    margin-top: 10px;
  }

  .button {
    font-size: larger;
    border: none;
    background: unset !important;
    outline: none;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    width: fit-content;
    padding: 5px;
    cursor: pointer;

    &:hover {
      background: transparent;
    }
  }

  .roulette-button {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: +9;
    border-radius: 5rem;
    width: 5rem;
    height: 5rem;
    transform: translate(-55%, -55%);
    background: white;
    color: #000;
    font-size: 0.8rem;
    font-weight: bold;
  }

  // .pointer {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   z-index: +9;
  //   border-radius: 5rem;
  //   width: 300px;
  //   height: auto;
  //   transform: translate(-50%, -53%);
  // }

  .roulette-container {
    position: relative;
    width: 100%;
    max-width: 550px;
    aspect-ratio: 1;
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;
  }

  .roulette-container > div {
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
    transform: rotate(-47deg);
  }

  .roulette-container > div > div {
    position: relative !important;
  }

  .out {
    background-color: grey !important;
  }

  .outerBorder {
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    z-index: 6;
  }

  .gift {
    margin-top: 20px;
    text-align: center;
    color: #fff;

    &_title {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 20px;
    }

    &__box {
      // border: 3px solid #b04808;
      border-radius: 10px;
      background-color: #fff;
      color: #b04808;
      margin-top: 10px;
    }

    &_item {
      display: flex;
      justify-content: start;
      align-items: center;
      padding: 5px;

      &-image {
        width: 100px;

        img {
          width: auto;
          max-width: 70px;
          background-color: #fff;
          max-height: 40px;
        }
      }

      &-desc {
        font-size: 15px;
        margin-left: 20px;
        max-width: 300px;
        font-weight: 500;
      }
    }
  }
}

.wheel {
  min-height: 100vh;
  max-width: 420px;
  overflow: hidden;
  margin: 0 auto;
  // background-color: #c12f18;

  &__container {
    padding: 0 10px 0 10px;
    position: relative;
    height: 100%;
  }

  &_btn {
    background-color: #00ac47;
    border-radius: 10px;
    margin: 0 auto;
    left: 0;
    right: 0;
    display: block;
    width: 200px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    border: 2px solid #fff;
    position: absolute;
    padding: 10px;
    bottom: 1rem;
  }
}

.wheel > div {
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
  padding-top: 40px;
  height: 100vh;
}
